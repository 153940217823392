@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Roboto", system-ui, sans-serif;
    }

    body {
        color: #727272;
        font-weight: 400;
    }

    .app-container {
        @apply container mx-auto relative px-6 max-w-[1320px];
    }

    h1 {
        @apply text-xl lg:text-5xl;
    }
}

.splide__arrow  {
    opacity: 1 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    padding: 10px !important;
    height: 3rem !important;
    width: 3rem !important;
}

.splide__arrow:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.splide__arrow--prev {
    left: -1.5rem !important;
}

.splide__arrow--next {
    right: -1.5rem !important;
}

.splide__pagination {
    position: relative !important;
    top: 10px !important;
}